import { Link } from "react-router-dom";
import { FaInstagram, FaLinkedin } from "react-icons/fa";
import "../styles/Footer.scss";

export const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-row">
        <div className="footer-column footer-links">
          <p className="column-heading">Unternehmen</p>
          <Link to="/Impressum">Impressum</Link>
          <Link to="/Datenschutz">Datenschutz</Link>
          <Link to="/Kontakt">Kontakt</Link>
        </div>
        <div className="footer-column footer-icons">
          <p className="column-heading">Finden Sie uns hier</p>
          <a
            className="icon"
            href="https://www.instagram.com/complete.it/"
            target={"_blank"}
            rel="noreferrer"
          >
            <FaInstagram size={30} />
          </a>
          <a
            className="icon"
            href="https://www.linkedin.com/company/complete-it-og"
            target={"_blank"}
            rel="noreferrer"
          >
            <FaLinkedin size={30} />
          </a>
        </div>
      </div>
      <div className="footer-row">
        &copy; Complete IT OG {new Date().getFullYear()}
      </div>
    </div>
  );
};
