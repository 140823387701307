import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { TextField, Button } from "@mui/material";
import { BsFillTelephoneFill } from "react-icons/bs";
import { HiMail } from "react-icons/hi";
import axios from "axios";
import "../styles/Contact.scss";

export const Contact = ({ title }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);

  const helper = {
    email: {
      required: "Pflichtfeld",
      pattern: "Ungültige E-Mail",
    },
  };

  const { handleSubmit, reset, control } = useForm();
  const [submitted, setSubmitted] = useState(false);

  const onSubmit = (data) => {
    axios.get(
      `https://api.completeit.at?name=${data["name"]}&mail=${data["email"]}&header=${data["subject"]}&message=${data["message"]}`
    );
    setSubmitted(true);
    reset();
  };

  return (
    <Container>
      <Row className="container-row">
        <h2>Kontaktieren Sie uns!</h2>
      </Row>
      <Row className="container-row">
        <p>
          Haben Sie eine Frage? Dann zögern Sie nicht uns direkt zu
          kontaktieren.
          <br />
          Unser Team wird sich so schnell wie möglich bei Ihnen melden um Ihnen
          zu helfen.
        </p>
      </Row>
      <Row md="auto" className="form-row">
        <Col xs={12} lg={7} className="form-col">
          <form className="container-form">
            <Row className="inpu-row">
              <Col xs={6} className="input-fields">
                <Controller
                  name="name"
                  defaultValue={""}
                  rules={{ required: true }}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      className="input"
                      onChange={onChange}
                      value={value}
                      label={"Name"}
                      error={error !== undefined}
                      helperText={error ? "Pflichtfeld" : ""}
                      sx={{ minHeight: "90px" }}
                    />
                  )}
                />
              </Col>
              <Col xs={6} className="input-fields">
                <Controller
                  name="email"
                  defaultValue={""}
                  rules={{
                    required: true,
                    pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                  }}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      className="input"
                      type="email"
                      onChange={onChange}
                      value={value}
                      label={"E-Mail"}
                      error={error !== undefined}
                      helperText={error ? helper.email[error.type] : ""}
                      sx={{ minHeight: "90px" }}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row className="input-row">
              <Col className="input-fields">
                <Controller
                  name="subject"
                  defaultValue={""}
                  rules={{ required: true }}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      className="input"
                      onChange={onChange}
                      value={value}
                      label={"Betreff"}
                      error={error !== undefined}
                      helperText={error ? "Pflichtfeld" : ""}
                      sx={{ minHeight: "90px" }}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row className="input-row">
              <Col className="input-fields">
                <Controller
                  name="message"
                  defaultValue={""}
                  rules={{ required: true }}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      className="input"
                      onChange={onChange}
                      value={value}
                      multiline
                      rows={4}
                      label={"Deine Nachricht"}
                      error={error !== undefined}
                      helperText={error ? "Pflichtfeld" : ""}
                      sx={{ minHeight: "160px" }}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row className="input-fields">
              <Col xs={3}>
                <Button
                  className="submit-button"
                  onClick={handleSubmit(onSubmit)}
                  variant="outlined"
                  sx={{ my: 3 }}
                >
                  Senden
                </Button>
              </Col>
            </Row>
            {(() => {
              if (submitted) {
                return (
                  <Row className="input-row">
                    <Col className="confirmation-col">
                      <p className="confirmation-text">
                        Danke für Ihre Anfrage. Wir haben Ihre Nachricht
                        erhalten und melden uns so schnell wie möglich bei
                        Ihnen.
                      </p>
                    </Col>
                  </Row>
                );
              } else {
                return null;
              }
            })()}
          </form>
        </Col>
        <Col xs={12} lg={2} className="legend-col">
          <Row>
            <a href="tel:+4366475148874">
              <Col className="legends">
                <Row md="auto" className="legend-row">
                  <BsFillTelephoneFill size={50} />
                </Row>
                <Row md="auto" className="legend-row">
                  <p>+43 664 75148874</p>
                </Row>
              </Col>
            </a>
            <a href="mailto:office@completeit.at">
              <Col className="legends">
                <Row md="auto" className="legend-row">
                  <HiMail size={50} />
                </Row>
                <Row md="auto" className="legend-row">
                  <p>office@completeit.at</p>
                </Row>
              </Col>
            </a>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
