import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { HomeCarousel } from "../components/index.js";
import { IoBuild } from "react-icons/io5";
import { BsWifi } from "react-icons/bs";
import { FaServer } from "react-icons/fa";
import { BiError } from "react-icons/bi";
import Logo from "../media/cube.webp";
import "../styles/Home.scss";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

export const Home = ({ title }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);
  return (
    <div className="home-container">
      <HomeCarousel />
      <Container>
        <Row className="home-container-row">
          <Col md="auto" className="col-icon">
            <div className="icon-container">
              <BiError className="onlyWeb" size={100} />
              <BiError className="onlyMobile" size={60} />
            </div>
          </Col>
          <Col>
            <h2>Computer defekt? Festplatte voll?</h2>
            <p>
              Wenn mal wieder nichts mehr geht, sind wir zur Stelle. Wir beheben
              Software Fehler, tauschen defekte Teile und rüsten Ihren PC auf.
            </p>
          </Col>
        </Row>
        <Row className="home-container-row">
          <Col md="auto" className="col-icon">
            <div className="icon-container">
              <FaServer className="onlyWeb" size={100} />
              <FaServer className="onlyMobile" size={60} />
            </div>
          </Col>
          <Col>
            <h2>Der individuelle Web-Auftritt für Ihr Unternehmen</h2>
            <p>
              Wenn die Website nicht aktuell ist oder unprofessionell wirkt,
              schreckt das neue Kunden schnell ab. Damit genau das nicht
              passiert, kümmern wir uns um Ihren individuellen Webauftritt.
            </p>
          </Col>
        </Row>
        <Row className="home-container-row">
          <Col md="auto" className="col-icon">
            <div className="icon-container">
              <IoBuild className="onlyWeb" size={100} />
              <IoBuild className="onlyMobile" size={60} />
            </div>
          </Col>
          <Col>
            <h2>Neuer PC geplant? Fragen zu den Komponenten?</h2>
            <p>
              Wir wissen alles über die neuesten PC-Komponenten und beraten von
              der Idee bis zum Kauf. Falls Sie Ihren ganz persönlichen Computer
              möchten, bauen wir ihn auch gerne für Sie zusammen.
            </p>
          </Col>
        </Row>
        <Row className="home-container-row">
          <Col md="auto" className="col-icon">
            <div className="icon-container">
              <BsWifi className="onlyWeb" size={100} />
              <BsWifi className="onlyMobile" size={60} />
            </div>
          </Col>
          <Col>
            <h2>Bestens vernetzt, egal wo</h2>
            <p>
              Egal ob im Büro oder zu Hause, wenn das Netzwerk nicht so
              funktioniert wie man das gerne hätte, steht schnell alles still.
              Wir planen, bauen und optimieren Netzwerke aller Art.
            </p>
          </Col>
        </Row>
      </Container>
      <Container fluid className="first-contact">
        <Container>
          <Row className="home-container-row">
            <Col md="auto">
              <img src={Logo} alt="logo" />
            </Col>
            <Col className="contact-row">
              <h2>Jetzt kostenlos anfragen</h2>
              <p>
                Sie haben Fragen zu unseren Services, stehen vor einem neuen
                Projekt oder wissen noch gar nicht wo es genau hingehen soll?
                Zögern Sie nicht uns zu kontaktieren, wir kümmern uns gerne um
                Ihr Anliegen. Auch Anfragen zu nicht auf unserer Website
                erwähnten IT-Services können Sie gerne an uns richten.
              </p>
              <Link to="/Kontakt">
                <Button color="inherit" variant="outlined">
                  Unverbindlich kontaktieren
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container>
        <Row className="home-container-row">
          <Col className="about">
            <h2>Ihr kundennaher IT-Dienstleister aus dem Burgenland</h2>
            <p>
              Angesiedelt im sonnigsten Bundesland Österreichs, betreuen wir all
              unsere Kunden persönlich und bieten individuelle Leistungen an.
              Wir kümmern uns von Endgeräten bis hin zu komplexen Netzwerken,
              Webseiten und Registrierkassen und alles, was mit IT zu tun hat.
              Wir haben definitiv das Richtige für Sie. Unser Team kümmert sich
              persönlich um Sie, um zielgerichtet auf Ihre Wünsche und Ihr
              Budget eingehen zu können. So können wir für Ihren Betrieb oder
              Ihr Zuhause das Beste herausholen, damit garantiert niemand
              digital auf der Strecke bleibt.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
