import { Paper, Button } from "@mui/material";
import { useEffect } from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import { InfoCard } from "../components/InfoCard.js";
import { LinkContainer } from "react-router-bootstrap";
import { HiOutlinePlus, HiOutlineStar, HiOutlineUser } from "react-icons/hi";
import WebDesign from "../media/WebDesignMockup.webp";
import "../styles/Website.scss";

export const Website = ({ title }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <Container className="website-container">
      <Row className="outside-paper-row">
        <Paper elevation={2}>
          <Container>
            <Row className="paper-row">
              <Col xs={12} lg={6} className="paper-col">
                <Row className="paper-text-row">
                  <h2>Ihr personalisierter Internetauftritt</h2>
                  <p>
                    Bei uns sind Sie bestens aufgehoben, wenn es darum geht,
                    Ihrem Unternehmen ein digitales Gesicht zu geben. Wir machen
                    alles möglich, egal ob kleine Info-Seiten oder komplexe
                    Web-Applikationen.
                  </p>
                  <p>
                    Unser Team begleitet Sie von der Idee bis zum Release Ihrer
                    neuen Webpräsenz. Da wir eine Vielzahl an Technologien von
                    Wordpress bis hin zu React einsetzen, sind Sie mit jeder
                    Idee und jedem Budget bei uns richtig. Ihre Website wird
                    genau auf Sie und Ihre Kunden zugeschnitten.
                  </p>
                  <p>
                    Vereinbaren Sie direkt einen Termin für Ihr Erstgespräch und
                    erzählen Sie uns von Ihren Wünschen und Ideen.
                  </p>
                </Row>
              </Col>
              <Col xs={12} lg={4} className="paper-col mockup-col">
                <Image
                  src={WebDesign}
                  alt="Web Design Mockup"
                  className="mockup"
                />
              </Col>
            </Row>
          </Container>
        </Paper>
      </Row>
      <Container>
        <Row className="container-row">
          <h2>Pakete</h2>
        </Row>
        <Row className="container-row">
          <Col md="auto" className="card-col">
            <InfoCard
              header="Basic Hosting"
              pricing="22,40€"
              bullets={[
                "Eine at-Domain*",
                "1 IMAP-Postfach",
                "Basis-Service**",
              ]}
              Icon={HiOutlinePlus}
            />
          </Col>
          <Col md="auto" className="card-col">
            <InfoCard
              header="Advanced Hosting"
              pricing="35,90€"
              bullets={[
                "Zwei at-Domains*",
                "2 Exchange Postfächer",
                "1 Service-Stunde**",
              ]}
              Icon={HiOutlineStar}
              highlight
            />
          </Col>
          <Col md="auto" className="card-col">
            <InfoCard
              header="Individual Hosting"
              pricing="individueller Preis"
              bullets={[
                "Beliebig viele Domains",
                "Individuelle Postfächer-Anzahl",
                "Service Stunden nach Vereinbarung",
              ]}
              Icon={HiOutlineUser}
            />
          </Col>
        </Row>
        <Row className="container-row">
          <Col xs={12} lg={8}>
            <Row className="container-row">
              <h2>Noch Fragen oder schon eine Entscheidung getroffen?</h2>
              <p>
                Wenn Sie noch Fragen zu einem unserer Pakete oder sich bereits
                für eines entschieden haben, dann zögern Sie nicht uns zu
                kontaktieren. Wir werden uns so schnell wie möglich um Ihr
                Anliegen kümmern.
              </p>
              <LinkContainer to="/Kontakt">
                <Button
                  className="contact-button"
                  variant="outlined"
                  sx={{
                    width: "200px",
                    height: "50px",
                    margin: "auto",
                  }}
                >
                  Anfrage senden
                </Button>
              </LinkContainer>
            </Row>
          </Col>
        </Row>
        <Row className="container-row">
          <p className="info-text">
            * Weitere Domains oder andere Domainendungen sind möglich. Ggf.
            fällt dafür ein Aufpreis an.
          </p>
          <p className="info-text">
            ** Im Basis-Service sind die Einrichtung und die für den Betrieb
            notwendigen Services enthalten. Service-Stunden gelten pro Monat und
            können nicht ins nächste Monat mitgenommen werden. Die Kosten für
            jede weitere Service-Stunde betragen 100€*.
          </p>
          <p className="info-text">Alle Preise exklusive Ust.</p>
        </Row>
      </Container>
    </Container>
  );
};
