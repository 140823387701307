import { useEffect } from "react";
import "../styles/Infrastructure.scss";
import { Col, Container, Row } from "react-bootstrap";

import { BsWifi, BsHddNetworkFill, BsPencilFill } from "react-icons/bs";

import header from "../media/network_bakcground.webp";
import phone from "../media/infra-smartphone.webp";
import work from "../media/infra-work.webp";
import out from "../media/infra-outside.webp";
import stream from "../media/infra-streaming.webp";
import logo from "../media/cube_square.webp";

export const Infrastructure = ({ title }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);
  return (
    <div>
      <div className="header">
        <div className="header-text">
          <h1>Vollständig vernetzt</h1>
          <h3 className="onlyWeb">Zuhause, im Büro oder im Geschäftslokal</h3>
        </div>
        <img src={header} alt="header" className="header-image" />
      </div>
      <Container className="infra-container">
        <Row className="highlight">
          <Col className="highlight-col">
            <BsWifi className="onlyWeb" size={100} />
            <BsWifi className="onlyMobile" size={70} />
            <div>
              <h4>Erweiterung bestehender Netzwerke</h4>
              <p>
                Wir verstärken WLAN-Signale oder installieren zusätzliche
                Hardware
              </p>
            </div>
          </Col>
          <Col className="highlight-col">
            <BsPencilFill className="onlyWeb" size={100} />
            <BsPencilFill className="onlyMobile" size={70} />
            <div>
              <h4>Beratung bei der Netzwerkplanung</h4>
              <p>
                Wir beraten vor Anschaffung und Installation Ihres Netzwerks
              </p>
            </div>
          </Col>
          <Col className="highlight-col">
            <BsHddNetworkFill className="onlyWeb" size={100} />
            <BsHddNetworkFill className="onlyMobile" size={70} />
            <div>
              <h4>Installation von Netzwerken</h4>
              <p>Montage und Einrichtung neuer Netzwerksysteme</p>
            </div>
          </Col>
        </Row>
        <Row>
          <p>
            Ob Zuhause oder im Büro, das Internet ist aus unserem Alltag nicht
            mehr wegzudenken. Deshalb ist es auch wichtig, dass Ihr Netzwerk
            immer einwandfrei funktioniert und stabil läuft. Wir helfen Ihnen
            dabei, Ihr Netzwerk zu planen, zu installieren und zu warten. Egal
            ob Sie ein neues Netzwerk benötigen oder Ihr bestehendes Netzwerk
            erweitern wollen, wir sind für Sie da.
          </p>
        </Row>
        <Row className="infra-img-row">
          <div className="infra-img">
            <div className="infra-img-col">
              <img src={phone} alt="phone" />
              <img src={out} alt="outside" />
            </div>
            <div className="infra-img-col">
              <img src={work} alt="phone" />
              <img src={stream} alt="outside" />
            </div>
          </div>
          <div className="overlay-container">
            <img className="overlay-logo" src={logo} alt="logo" />
          </div>
        </Row>
        <Row>
          <p>
            Auch Smart Home ist ein wesentlicher Bestandteil unseres Alltags
            geworden und wird immer weiter verbreitet. Jedoch können Smart Home
            Landschaften oftmals sehr schnell sehr komplex werden. Damit Ihnen
            der reibungslose Sprung in die moderne Zukunft gelingt, stehen wir
            Ihnen gerne bei der Planung und Installation Ihres Smart Homes zur
            Seite oder erweitern dieses auf Wunsch.
          </p>
        </Row>
      </Container>
    </div>
  );
};
