import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Link, NavLink } from "react-router-dom";
import "../styles/Navigation.scss";
import Logo from "../media/cube.webp";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import {
  createTheme,
  ThemeProvider,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  Drawer,
  IconButton,
  Typography,
} from "@mui/material";

const muiTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#ffffff",
    },
    secondary: {
      main: "#009fe3",
    },
  },
  components: {
    MuiToolbar: {
      styleOverrides: {
        dense: {
          height: 45,
          minHeight: 45,
          alignItems: "center",
        },
      },
    },
  },
});

export const Navigation = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <div>
      <Toolbar />
      <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
        <List>
          <ListItem disablePadding>
            <NavLink to="/" className="navbar-link">
              <ListItemButton sx={{ textAlign: "center", height: "60px" }}>
                Home
              </ListItemButton>
            </NavLink>
          </ListItem>
          <Divider
            orientation="horizontal"
            variant="middle"
            flexItem
            className="navbar-divider-mobile"
          />
          <ListItem disablePadding>
            <NavLink to="/Websites" className="navbar-link">
              <ListItemButton sx={{ textAlign: "center", height: "60px" }}>
                Websites
              </ListItemButton>
            </NavLink>
          </ListItem>
          <Divider
            orientation="horizontal"
            variant="middle"
            flexItem
            className="navbar-divider-mobile"
          />
          <ListItem disablePadding>
            <NavLink to="/Netzwerke" className="navbar-link">
              <ListItemButton sx={{ textAlign: "center", height: "60px" }}>
                Netzwerke
              </ListItemButton>
            </NavLink>
          </ListItem>
          <Divider
            orientation="horizontal"
            variant="middle"
            flexItem
            className="navbar-divider-mobile"
          />
          <ListItem disablePadding>
            <NavLink to="/Service" className="navbar-link">
              <ListItemButton sx={{ textAlign: "center", height: "60px" }}>
                PC Service
              </ListItemButton>
            </NavLink>
          </ListItem>
          <Divider
            orientation="horizontal"
            variant="middle"
            flexItem
            className="navbar-divider-mobile"
          />
          <ListItem disablePadding>
            <NavLink to="https://www.kassenchef.at/" className="navbar-link">
              <ListItemButton sx={{ textAlign: "center", height: "60px" }}>
              Registrierkassen
              </ListItemButton>
            </NavLink>
          </ListItem>
          <Divider
            orientation="horizontal"
            variant="middle"
            flexItem
            className="navbar-divider-mobile"
          />
          <ListItem disablePadding>
            <NavLink to="/Ueber" className="navbar-link">
              <ListItemButton sx={{ textAlign: "center", height: "60px" }}>
                Über uns
              </ListItemButton>
            </NavLink>
          </ListItem>
          <Divider
            orientation="horizontal"
            variant="middle"
            flexItem
            className="navbar-divider-mobile"
          />
          <ListItem disablePadding>
            <NavLink to="/Kontakt" className="navbar-link">
              <ListItemButton sx={{ textAlign: "center", height: "60px" }}>
                Kontakt
              </ListItemButton>
            </NavLink>
          </ListItem>
        </List>
      </Box>
    </div>
  );

  return (
    <ThemeProvider theme={muiTheme}>
      <Box className="navbar-box">
        <AppBar
          position="sticky"
          elevation={2}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Toolbar disableGutters variant="dense" className="navbar-toolbar">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { md: "none" } }}
              className="navbar-menu-button-mobile"
            >
              <MenuIcon />
            </IconButton>
            <LinkContainer to="/" className="navbar-logo">
              <Link className="navbar-logo-link">
                <img
                  className="navbar-logo-img"
                  src={Logo}
                  width={36.6}
                  height={41}
                  alt="Logo"
                />
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  className="navbar-logo-text"
                >
                  Complete IT
                </Typography>
              </Link>
            </LinkContainer>
            <div className="navbar-spacer" />
            <img
              className="navbar-logo-img-mobile"
              src={Logo}
              width={36.6}
              alt="Logo"
            />
            <Typography
              variant="h6"
              noWrap
              component="div"
              className="navbar-logo-text-mobile"
            >
              Complete IT
            </Typography>
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <Button className="nav-button">
                <NavLink to="/Websites" className="navbar-link">
                  Websites
                </NavLink>
              </Button>
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                className="navbar-divider"
              />
              <Button className="nav-button">
                <NavLink to="/Netzwerke" className="navbar-link">
                  Netzwerke
                </NavLink>
              </Button>
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                className="navbar-divider"
              />
              <Button className="nav-button">
                <NavLink to="/Service" className="navbar-link">
                  PC Service
                </NavLink>
              </Button>
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                className="navbar-divider"
              />
              <Button className="nav-button">
                <NavLink to="https://www.kassenchef.at/" target="_blank" className="navbar-link">
                Registrierkassen
                </NavLink>
              </Button>
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                className="navbar-divider"
              />
              <Button className="nav-button">
                <NavLink to="/Ueber" className="navbar-link">
                  Über uns
                </NavLink>
              </Button>
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                className="navbar-divider"
              />
              <Button className="nav-button">
                <NavLink to="/Kontakt" className="navbar-link">
                  Kontakt
                </NavLink>
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
        <Box>
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: 240,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
    </ThemeProvider>

    // <Navbar bg="light" expand="lg" className="bar">
    //   <LinkContainer to="/" className="navbar-logo">
    //     <Link>
    //       <img className="navbar-logo-img" src={Logo} width={50} alt="Logo" />
    //       Complete IT
    //     </Link>
    //   </LinkContainer>
    //   <Navbar.Toggle aria-controls="basic-navbar-nav" className="toggler" />
    //   <Navbar.Collapse id="basic-navbar-nav" className="navbar-styles">
    //     <Nav className="mr-auto">
    //       <LinkContainer to="/">
    //         <Link className="navbar-link">Home</Link>
    //       </LinkContainer>
    //       <NavDropdown title="Produkte">
    //         <NavDropdown.Item>
    //           <LinkContainer to="/Produkte/Websites">
    //             <Link className="navbar-link">Websites</Link>
    //           </LinkContainer>
    //         </NavDropdown.Item>
    //         <NavDropdown.Item>
    //           <LinkContainer to="/Produkte/Netzwerke">
    //             <Link className="navbar-link">Netzwerke</Link>
    //           </LinkContainer>
    //         </NavDropdown.Item>
    //         <NavDropdown.Item>
    //           <LinkContainer to="/Produkte/Service">
    //             <Link className="navbar-link">PC Service</Link>
    //           </LinkContainer>
    //         </NavDropdown.Item>
    //       </NavDropdown>
    //       <LinkContainer to="/Ueber">
    //         <Link className="navbar-link">Über uns</Link>
    //       </LinkContainer>
    //       <LinkContainer to="/Kontakt">
    //         <Link className="navbar-link">Kontakt</Link>
    //       </LinkContainer>
    //     </Nav>
    //   </Navbar.Collapse>
    // </Navbar>
  );
};
