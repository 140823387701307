import { useEffect } from "react";
import "../styles/Service.scss";
import { Container, Col, Row } from "react-bootstrap";
import { Paper } from "@mui/material";

export const Service = ({ title }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);
  return (
    <Container className="website-container">
      <Row className="banner-row">
        <Col xs={12} sm={10} lg={4} className="banner-col">
          <Paper elevation={2} className="banner service-banner">
            <h1 className="banner-header">Service</h1>
          </Paper>
        </Col>
        <Col xs={12} sm={10} lg={4} className="banner-col">
          <Paper elevation={2} className="banner configuration-banner">
            <h1 className="banner-header">Konfiguration</h1>
          </Paper>
        </Col>
        <Col xs={12} sm={10} lg={4} className="banner-col">
          <Paper elevation={2} className="banner maintenance-banner">
            <h1 className="banner-header">Wartung</h1>
          </Paper>
        </Col>
      </Row>
      <Row className="container-row">
        <Col xs={12} sm={10} lg={5}>
          <Row>
            <h2>Service</h2>
          </Row>
          <Row>
            <p>
              Benötigen Sie Hilfe mit Ihrem PC, Laptop oder Handy? Egal ob
              Reparatur, Aufrüstung oder Software Updates, wir kümmern uns um
              Ihr Anliegen.
            </p>
          </Row>
        </Col>
        <Col xs={12} sm={10} lg={5}>
          <Row>
            <h2>Konfiguration</h2>
          </Row>
          <Row>
            <p>
              Wir stehen Ihnen bei der Anschaffung Ihres PCs oder Laptop zur
              Seite. Egal ob reiner Kauf oder individuell konfiguriert. Wir
              bauen gerne Ihren Traum PC und richten diesen für ein angenehmes
              out of the Box Erlebnis ein.
            </p>
          </Row>
        </Col>
        <Col xs={12} sm={10} lg={5}>
          <Row>
            <h2>Wartung</h2>
          </Row>
          <Row>
            <p>
              Wir sorgen dafür, dass Ihre Geräte immer auf dem neuesten Stand
              und stets einsatzbereit sind. Egal ob PC, Laptop, Drucker oder
              Netzwerktechnik. Wir kümmern uns um all Ihre Geräte damit Ihr
              Alltag reibungslos verläuft.
            </p>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
