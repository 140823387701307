import { Card, CardContent, Divider } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import "../styles/InfoCard.scss";

export const InfoCard = ({ header, pricing, bullets, Icon, highlight }) => {
  return (
    <Card sx={{ maxWidth: 300 }} className="package-card">
      <CardContent className="card-content">
        <div className="top-background-div">
          <h3 className="pricing-header">{header}</h3>
          <Divider
            flexItem={true}
            variant="middle"
            sx={{ bgcolor: "white", borderWidth: 0.5 }}
          />
          <h4 className="pricing-text">
            {pricing}
            <br></br>pro Monat
          </h4>
          <div className="symbol-circle">
            <Icon size={40} className="symbol" />
          </div>
        </div>
        <div className="bottom-background-div">
          {bullets.map((bullet, i) => {
            if (i + 1 !== bullets.length) {
              return (
                <div key={i}>
                  <Row className="container-row">
                    <Col xs={10} className="container-col">
                      <p className="point-text">{bullet}</p>
                    </Col>
                  </Row>
                  <Divider
                    flexItem={true}
                    variant="middle"
                    className="bullet-divider"
                  />
                </div>
              );
            } else {
              return (
                <div key={i}>
                  <Row className="container-row">
                    <Col xs={10} className="container-col">
                      <p className="point-text">{bullet}</p>
                    </Col>
                  </Row>
                </div>
              );
            }
          })}
        </div>
      </CardContent>
    </Card>
  );
};
