import { Route, Routes } from "react-router-dom";
import { Footer, Navigation } from "./components/index";
import {
  Home,
  Contact,
  Error,
  Service,
  Website,
  Infrastructure,
  About,
  Imprint,
  DataPolicy,
} from "./pages/index";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <>
      <ScrollToTop />
      <Navigation />
      <div className="page-container">
        <Routes>
          <Route path="/" element={<Home title="Complete IT" />} />
          <Route
            path="/Kontakt"
            element={<Contact title="Kontakt - Complete IT" />}
          />
          <Route
            path="/Ueber"
            element={<About title="Über uns - Complete IT" />}
          />
          <Route
            path="/Netzwerke"
            element={<Infrastructure title="Netzwerke - Complete IT" />}
          />
          <Route
            path="/Websites"
            element={<Website title="Websites - Complete IT" />}
          />
          <Route
            path="/Service"
            element={<Service title="Service - Complete IT" />}
          />
          <Route
            path="/Impressum"
            element={<Imprint title="Impressum - Complete IT" />}
          />
          <Route
            path="/Datenschutz"
            element={<DataPolicy title="Datenschutz - Complete IT" />}
          />
          <Route
            path="/*"
            element={
              <Error title="Diese seite exisitert nicht - Complete IT" />
            }
          />
        </Routes>
      </div>
      <Footer />
    </>
  );
}

export default App;
