import { useEffect } from "react";
import { Container, Row } from "react-bootstrap"
import Banner from "../media/error-banner.webp"

export const Error = ({title}) => {
    useEffect(() => {
        document.title = title;
    }, [title]);
    return (
        <div className="error">
            <div className="error-banner">
                <img src={Banner} alt="Error" />
            </div>
            <Container>
                <Row>
                    <h1>Hopalla, du hast dich verlaufen?</h1>
                </Row>
                <Row>
                    <h2>Wenn du dich im Dschungel der Wesbite-Planung auch oft verläufst sind wir für dich da</h2>
                </Row>
            </Container>
        </div>
    )
}